.igl-verify-report-container {
    overflow: clip;
}

.verify-report-header {
    background: #111 url(../../assets/verify_report_bg.svg) no-repeat
    right bottom;
    padding: 81px 55px 80px 55px;
    align-items: center;
    margin-top: 125px;
    margin-bottom: 50px;
  }

.verify-report-header-title {
    color: #fff;
    font-family: Montserrat;
    font-size: 6.4rem;
    font-weight: 700;
  }

  
.report-summary-container {
    padding-left: 55px;
    margin-bottom: 60px;
  }
  
  .report-summary-header-title {
    color: #333;
    font-family: Montserrat;
    font-size: 4.4rem;
    font-weight: 700;
    margin-bottom: 68px;
  }

  @media screen and (max-width:1024px) {
    .report-summary-container {
        padding-left: 15px;
      } 
  }
