.report-card-form-container {
    margin: 5px 25px 20px 25px;
    padding: 20px 25px;
    border: 2px solid #666;
    border-radius: 38px;
  }
  
  .card-form-row {
    margin-bottom: 0px;
  }
  
  .card-title-field-container {
    display: flex;
    align-items: center;
  }
  
  .report-summary-image {
    /* background: url(../../assets/report_summary_img.png) no-repeat; */
    width: 250px;
    height: 250px;
    border-radius: 28px;
    margin-bottom: 28px;
  }
  
  .card-form-title {
    color: #171717;
    font-family: Merriweather;
    font-size: 2rem;
    font-weight: 500;
  }
  
  .card-form-value {
    color: #171717;
    font-family: Merriweather;
    font-size: 2rem;
    font-weight: 400;
    text-align: left;
  }

  @media screen and (max-width: 768px) {
    .report-card-form-container {
      margin: 5px 25px 20px 25px;
      padding: 10px 8px;
    }
  }

  @media screen and (max-width: 576px) {
    .report-image-container {
      margin-top: 20px;
      justify-content: left !important;
    }
  }