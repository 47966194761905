* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: Montserrat;
  font-size: 62.5%;
}

.row {
  margin-left: 0;
  margin-right: 0;
}

@media screen and (min-width: 1050px) and (max-width: 1300px) {
  * {
    font-size: 56%;
  }
}

@media screen and (min-width: 750px) and (max-width: 1050px) {
  * {
    font-size: 50%;
  } 
}

@media screen and (min-width: 600px) and (max-width: 750px) {
  * {
    font-size: 46%;
  }
}

@media screen and (min-width: 450px) and (max-width: 600px) {
  * {
    font-size: 42%;
  }
}

@media screen and (max-width: 450px) {
  * {
    font-size: 38%;
  }
}
