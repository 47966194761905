.igl-verify-report-container {
    overflow: clip;
}

.report-summary-card-container {
    margin-top: 125px;
    margin-bottom: 20px;
    padding-left: 55px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0 4rem;
}

.report-summary-card-header-title {
    color: #333;
    font-family: Montserrat;
    font-size: 2.4rem;
    font-weight: 500;
}

.report-summary-card-download-button {
    display: flex;
    padding: 6px 12px;
    justify-content: center;
    align-items: center;
    gap: 10px;
    border-radius: 4px;
    background: #242224;
    color: #fff;
    font-size: 2rem;
    border-color: transparent;
    font-family: Montserrat;
    cursor: pointer;
}