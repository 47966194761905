.contact-us-container {
  margin: 150px 0px 100px 0px;
  overflow-x: clip;
}

.contact-info-main {
  padding: 65px 105px;
  /* background-color: #e4e9fd; */
}

.background-diamond-container {
  background: #e4e9fd url(../../assets/contact_us_diamond_bg.svg) no-repeat
    right top;
  margin-bottom: 80px;
}

.contact-title {
  color: #000;
  font-family: Montserrat;
  font-size: 6.4rem;
  font-weight: 600;
}

.contact-description {
  color: #000;
  font-family: Montserrat;
  font-size: 3.2rem;
  font-weight: 400;
  margin-bottom: 24px;
}

.company-info-section-title {
  color: #333;
  font-family: Montserrat;
  font-size: 2.4rem;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  margin-bottom: 12px;
}

.company-info-section-description {
  color: #333;
  font-family: Montserrat;
  font-size: 2.2rem;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  margin-bottom: 12px;
}

.appointment-form-container {
  margin: 30px auto;
  width: 100%;
  height: auto;
  border-radius: 74px;
  background: linear-gradient(white, white, white) padding-box,
    linear-gradient(#91cffc, #0076e3ed, #fc9191e0) border-box;
  border: 4px solid transparent;
  border-radius: 74px;
  /* border-image: linear-gradient(#91cffc, #0076e3ed, #fc9191e0) 1; */
  padding: 100px 75px;
}

.apppointment-title {
  color: #333;
  font-family: Montserrat;
  font-size: 6.4rem;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  margin-bottom: 29px;
}

.apppointment-sub-title {
  color: #333;
  font-family: Montserrat;
  font-size: 3.6rem;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  margin-bottom: 50px;
}

.contact_submit_button {
  margin-top: 42px;
  display: flex;
  width: 320px;
  height: 80px;
  padding: 18px 36px;
  align-items: flex-start;
  justify-content: center;
  align-items: center;
  gap: 10px;
  border-radius: 50px;
  background: #242224;
  color: #fff;
  font-size: 2rem;
  border-color: transparent;
}

@media screen and (max-width: 600px) {
  .contact-info {
    padding: 65px 25px;
  }

  .contact-info-main {
    padding: 65px 30px;
  }

  .appointment-form-container {
    width: 92%;
    margin: auto;
    padding: 100px 25px;
    border-radius: 42px;
  }

  .contact_submit_button {
    width: 300px;
    margin: 42px auto 0px auto;
    font-size: 16px;
  }
}

@media screen and (max-width: 425px) {
  .contact_submit_button {
    width: 200px;
    margin: 42px auto 0px auto;
    font-size: 16px;
    padding: 10px;
    height: 60px;
  }
}
