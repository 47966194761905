.welcome-main-page {
  position: relative;
  margin-top: 180px;
  margin-bottom: 150px;
}

#text-over-container {
  position: absolute;
  color: #000;
  top: 50px;
  left: 90px;
}

#main_title {
  font-family: Montserrat;
  font-size: 7.2rem;
  font-weight: 600;
}

#sub_title {
  color: #686868;
  font-family: Montserrat;
  font-size: 3.8rem;
  font-weight: 500;
}

.main_header_button {
  margin-top: 42px;
  display: flex;
  width: auto;
  height: auto;
  padding: 15px 38px;
  justify-content: center;
  align-items: center;
  gap: 10px;
  border-radius: 90px;
  background: #242224;
  color: #fff;
  font-family: Montserrat;
  font-size: 2.4rem;
  font-weight: 700;
}

.svg_background {
  position: relative;
  width: 435px;
  height: 426px;
  max-width: 100%;
  max-height: 100%;
  border-radius: 54px;
  background-color: #e4e9fd;
  z-index: -1;
  border: 1px solid #d0d7f2;
}

.diamond_svg {
  position: absolute;
  z-index: 15;
  top: -79px;
  left: 8px;
}

.border_parent {
  position: relative;
}

.diamond_border_svg {
  position: absolute;
  z-index: -10;
  top: 26px;
  left: -29px;
}

.detail-card {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: 38px !important;
}

.outer-circle {
  display: flex;
  width: 88px;
  height: 88px;
  padding: 5px 25px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 10px;
  border-radius: 60px;
  border: 1px solid #d4d4d4;
}

.inner-number {
  color: #e4e9fd;
  font-family: Montserrat;
  font-size: 64px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
}

.certification-container {
  width: 96%;
  margin: 22px auto;
  height: 426px;
  border-radius: 54px;
  background: #e4e9fd url(../../assets/rectangle359_gradient.svg) no-repeat left
    bottom;
  z-index: -2;
  margin-bottom: 150px;
}

#card_title {
  color: #333333;
  font-size: 3.6rem;
  font-weight: 700;
  text-align: center;
}

#card_description {
  color: #4f4f4f;
  font-family: Merriweather;
  font-size: 2.4rem;
  font-weight: 400;
  line-height: 40px; /* 166.667% */
}

#jewelry_title {
  font-size: 5.6rem;
  font-weight: 600;
}

.mainpage_footer {
  height: 612px;
  width: 100%;
  background-color: #111;
  position: relative;
  padding: 117px 248px 196px 97px;
  z-index: 1;
  /* align-items: center; */
}

.moon_svg {
  position: absolute;
  top: -300px;
  right: 0;
  z-index: -1;
}

.footer_title {
  color: #fff;
  font-family: Montserrat;
  font-size: 4.8rem;
  font-weight: 700;
  margin-bottom: 5px;
  text-shadow: 2px 7px 5px rgba(0, 0, 0, 0.3),
    0px -4px 10px rgba(255, 255, 255, 0.3);
}

.footer_description {
  color: #fff;
  font-family: Montserrat;
  font-size: 4.2rem;
  font-style: normal;
  font-weight: 300;
  line-height: 54px; /* 128.571% */
  margin-bottom: 58px;
  text-shadow: 2px 7px 5px rgba(0, 0, 0, 1), 0px -4px 10px rgba(0, 0, 0, 1);
}

.footer_button {
  display: flex;
  width: 279px;
  height: 52px;
  padding: 18px 24px;
  align-items: flex-start;
  justify-content: center;
  align-items: center;
  gap: 10px;
  border-radius: 18px 4px;
  background: #ffffff;
  color: #000000;
  font-size: 2rem;
  color: #000;
  font-family: Montserrat;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
}

.result_container {
  border: 1px solid #d3d3d3;
  padding: 102px 58px 58px 58px;
  border-radius: 68px;
  margin-bottom: 100px;
}

.proven_results_header_container {
  display: flex;
  justify-content: center;
}

.proven_results {
  display: flex;
  justify-content: center;
}

/* Apply styles to the 1st, 2nd, and 4th elements with the class "results_description" */
.result_card:nth-child(1) .results_description,
.result_card:nth-child(2) .results_description,
.result_card:nth-child(4) .results_description {
  border-right: 1px solid #e0e0e0;
}

.proven_results_header {
  color: #323232;
  font-family: Montserrat;
  font-size: 4rem;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  position: absolute;
  top: -26px;
  background-color: white;
  padding-left: 13px;
  padding-right: 13px;
  z-index: 5;
}

.result_card {
  margin-bottom: 42px;
}

.results_title {
  color: #323232;
  font-family: Montserrat;
  font-size: 3.6rem;
  font-weight: 700;
  text-align: center;
}

.results_description {
  color: #858585;
  text-align: center;
  font-family: Merriweather;
  font-size: 2.4rem;
  font-weight: 400;
  line-height: 34px; /* 141.667% */
  padding: 5px 15px;
}

.small_diamond_svg {
  position: relative;
  left: -125px;
}

.jewelery-text-container {
  padding: 32px 0 62px 62px;
}

/* MEDIA QUERIES */

@media screen and (min-width: 1050px) and (max-width: 1300px) {
  #main_title {
    font-size: 5.4rem;
  }

  #sub_title {
    font-size: 3rem;
  }

  #jewelry_title {
    font-size: 5rem;
  }

  .footer_title {
    font-size: 3.8rem !important;
  }
  .footer_description {
    font-size: 3.2rem !important;
    line-height: 38px;
    margin-bottom: 25px;
  }
  .svg_background {
    width: 350px;
    height: 335px;
    border-radius: 44px;
  }

  .diamond_border_svg {
    width: 350px;
    height: 335px;
  }

  .diamond_svg {
    left: -10px;
  }
  .small_diamond_svg {
    position: relative;
    left: -165px;
  }
}

@media screen and (min-width: 750px) and (max-width: 1050px) {
  #main_title {
    font-size: 4.6rem;
  }

  #sub_title {
    font-size: 2.4rem;
  }

  .svg_background {
    height: 240px;
    width: 300px;
    border-radius: 36px;
  }

  .diamond_svg {
    left: -25px;
  }

  .diamond_border_svg {
    width: 280px;
    height: 245px;
  }

  #jewelry_title {
    font-size: 4.4rem;
    font-weight: 600;
  }

  .main_header_button {
    margin-top: 32px;
    font-size: 1.6rem;
  }
  .feature-card {
    margin: auto 75px;
  }
  .certification-container {
    height: 325px;
  }
  .small_diamond_svg {
    position: relative;
    left: -160px;
    height: 330px;
  }
  .proven_results_header {
    font-size: 3rem;
    top: -16px;
  }
}

@media screen and (min-width: 600px) and (max-width: 750px) {
  .welcome-main-page {
    margin-top: 165px;
  }

  #text-over-container {
    top: 20px;
    left: 30px;
  }

  #main_title {
    font-size: 3.8rem;
  }

  #sub_title {
    font-size: 2rem;
  }

  .svg_background {
    height: 180px;
    width: 300px;
    border-radius: 30px;
  }

  .diamond_border_svg {
    width: 231px;
    height: 196px;
    top: 12px;
    left: -23px;
  }

  .diamond_svg {
    left: -25px;
    top: -60px;
  }

  #jewelry_title {
    font-size: 3.2rem;
    font-weight: 700;
  }

  .main_header_button {
    margin-top: 22px;
    width: 220px;
    height: 44px;
    padding: 15px 20px;
    font-size: 1.5rem;
  }
  .feature-card {
    margin: auto 40px;
  }
  .proven_results_header {
    font-size: 2.6rem;
    top: -16px;
  }
  .footer_title {
    font-size: 3.8rem !important;
  }
  .footer_description {
    font-size: 3.5rem !important;
    line-height: 28px;
    margin-bottom: 45px;
  }
  .certification-container {
    height: 275px;
    border-radius: 36px;
  }
  .small_diamond_svg {
    position: relative;
    left: -150px;
    height: 260px;
  }
}

@media screen and (min-width: 450px) and (max-width: 600px) {
  .welcome-main-page {
    margin-top: 130px;
  }

  #text-over-container {
    top: 20px;
    left: 30px;
  }

  #main_title {
    font-size: 3.6rem;
  }

  #sub_title {
    font-size: 2rem;
  }

  .diamond_border_svg {
    width: 163px;
    height: 156px;
    top: 12px;
    left: -14px;
  }

  .svg_background {
    height: 145px;
    width: 300px;
    border-radius: 24px;
  }

  .diamond_svg {
    top: -25px;
    left: -35px;
  }

  .certification-container {
    height: 245px;
    border-radius: 28px;
  }
  .small_diamond_svg {
    position: relative;
    left: -180px;
    height: 240px;
  }

  .jewelery-text-container {
    padding: 62px 0px 62px 20px;
    z-index: 9;
  }

  #jewelry_title {
    font-size: 3rem;
    font-weight: 700;
  }

  .main_header_button {
    margin-top: 10px;
    padding: 10px;
    width: 150px;
    height: 22px;
    font-size: 1.3rem;
  }
  .feature-card {
    margin: auto 15px;
  }

  .proven_results_header {
    font-size: 2.6rem;
    top: -8px;
  }

  .mainpage_footer {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 0 50px;
  }

  .footer_title {
    font-size: 3.2rem !important;
  }
  .footer_description {
    font-size: 3rem !important;
    line-height: 28px;
    margin-bottom: 20px;
  }
}

@media screen and (max-width: 450px) {
  .welcome-main-page {
    margin-top: 135px;
  }
  #text-over-container {
    top: 10px;
    left: 20px;
  }

  #main_title {
    font-size: 3rem;
  }

  #sub_title {
    font-size: 1.4rem;
  }

  .proven_results_header {
    font-size: 2.4rem;
    top: -2px;
  }

  .svg_background {
    height: 100px;
    width: 260px;
    border-radius: 18px;
    padding-right: 10px;
  }

  .diamond_border_svg {
    width: 113px;
    height: 176px;
    top: -25px;
    left: -11px;
  }

  .diamond_svg {
    top: -25px;
    left: 0px;
  }

  .certification-container {
    height: 200px;
    border-radius: 22px;
  }
  .small_diamond_svg {
    position: relative;
    left: -180px;
    height: 175px;
    opacity: 0.7;
    filter: drop-shadow(5px);
  }

  .jewelery-text-container {
    padding: 52px 0px 52px 20px;
    z-index: 9;
  }

  #jewelry_title {
    font-size: 2.4rem;
    font-weight: 700;
  }

  .main_header_button {
    margin-top: 10px;
    width: 150px;
    height: 15px;
    padding: 10px 12px;
    font-size: 1.4rem;
  }
  .feature-card {
    margin: auto 5px;
  }

  .mainpage_footer {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 0 50px;
  }
}

@media screen and (max-width: 380px) {
  .diamond_svg {
    top: -25px;
    left: -5px;
  }
  #main_title {
    font-size: 16px;
  }

  #sub_title {
    font-size: 10px;
  }

  #jewelry_title {
    font-size: 2rem;
  }
}

@media screen and (max-width: 768px) {
  .welcome-main-page {
    margin-bottom: 50px;
  }

  #card_description {
    padding: 10px 20px;
    line-height: 25px;
    text-align: center;
  }

  .result_card {
    margin-bottom: 20px;
  }

  .results_description {
    line-height: 22px;
  }
}

@media screen and (min-width: 768px) and (max-width: 1050px) {
  .mainpage_footer {
    padding: 117px 148px 196px 75px;
  }
}

@media screen and (max-width: 768px) {
  .mainpage_footer {
    padding: 100px 75px 100px 75px;
  }
  .certification-container {
    margin-bottom: 50px;
  }
}

@media screen and (min-width: 578px) and (max-width: 768px) {
  .result_card {
    padding: 0px !important;
  }
  /* Apply styles to the 1st, 3rd elements with the class "results_description" */
  .result_card:nth-child(1) .results_description,
  .border-right-styling {
    border-right: 1px solid #e0e0e0;
  }

  .result_card:nth-child(2) .results_description,
  .result_card:nth-child(4) .results_description {
    border-right: 1px solid transparent;
  }
}

@media screen and (max-width: 578px) {
  /* Apply styles to the 1st, 3rd elements with the class "results_description" */
  .result_card:nth-child(1) .results_description,
  .result_card:nth-child(2) .results_description,
  .border-right-styling,
  .result_card:nth-child(4) .results_description {
    border-style: none;
  }

  .result_container {
    padding: 58px 40px;
    width: 92%;
  }
}
