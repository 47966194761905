.input-text-container {
    position: relative;
}

.input-group-addon {
    width: 75px;
}

.input-group-addon-text {
    width: 75px;
    font-size: 50px !important;
    position: absolute;
    top: -28px;
    left: 10px;
}