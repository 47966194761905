.main-page-container {
  margin-top: 75px;
}

.mainpage {
  position: relative;
}

.smooth_bg {
  z-index: -1;
  width: 100% !important;
  height: 100% !important;
}

#text-over-container {
  position: absolute;
  color: #000;
  top: 50px;
  left: 90px;
}

#main_title {
  font-size: 8.4rem;
  font-weight: 700;
}

#sub_title {
  font-size: 4.8rem;
  font-weight: 500;
}

.diamond_svg {
  position: absolute;
  right: 50px;
  top: 80px;
  z-index: 1;
}

.hexagon_diamond_svg_container {
  overflow: hidden;
}

.main_header_button {
  margin-top: 42px;
  display: flex;
  width: 279px;
  height: 52px;
  padding: 18px 24px;
  align-items: flex-start;
  justify-content: center;
  align-items: center;
  gap: 10px;
  border-radius: 18px 4px;
  background: #242224;
  color: #fff;
  font-size: 1.6rem;
}

.feature-card {
  margin: auto 150px;
}
.feature_svgs {
  display: block;
  margin-left: auto;
  margin-right: auto;
}

.detail-card {
  margin-bottom: 15px;
}

#card_title {
  color: #333333;
  font-size: 3.6rem;
  font-weight: 700;
  text-align: center;
}

#card_description {
  color: #4f4f4f;
  font-family: Merriweather;
  font-size: 2.4rem;
  font-weight: 400;
  line-height: 40px; /* 166.667% */
}

#jewelry_title {
  font-size: 6.4rem;
  font-weight: 700;
}

.mainpage_footer {
  height: 612px;
  width: 100%;
  background-color: #111;
  position: relative;
  padding: 117px 248px 196px 97px;
  z-index: 1;
  /* align-items: center; */
}

.moon_svg {
  position: absolute;
  top: -300px;
  right: 0;
  z-index: -1;
}

.footer_title {
  color: #fff;
  font-family: Montserrat;
  font-size: 4.8rem;
  font-weight: 700;
  margin-bottom: 5px;
  text-shadow: 2px 7px 5px rgba(0, 0, 0, 0.3),
    0px -4px 10px rgba(255, 255, 255, 0.3);
}

.footer_description {
  color: #fff;
  font-family: Montserrat;
  font-size: 4.2rem;
  font-style: normal;
  font-weight: 300;
  line-height: 54px; /* 128.571% */
  margin-bottom: 58px;
  text-shadow: 2px 7px 5px rgba(0, 0, 0, 1), 0px -4px 10px rgba(0, 0, 0, 1);
}

.footer_button {
  display: flex;
  width: 279px;
  height: 52px;
  padding: 18px 24px;
  align-items: flex-start;
  justify-content: center;
  align-items: center;
  gap: 10px;
  border-radius: 18px 4px;
  background: #ffffff;
  color: #000000;
  font-size: 2rem;
  color: #000;
  font-family: Montserrat;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
}

.result_container {
  border: 1px solid #d3d3d3;
  padding: 58px;
  border-radius: 68px;
  margin-bottom: 100px;
}

.results_title {
  color: #323232;
  font-family: Montserrat;
  font-size: 3.6rem;
  font-weight: 700;
  text-align: center;
}

.results_description {
  color: #858585;
  text-align: center;
  font-family: Merriweather;
  font-size: 2.4rem;
  font-weight: 400;
  line-height: 34px; /* 141.667% */
}

/* MEDIA QUERIES */

@media screen and (min-width: 1300px) {
  #main_title {
    font-size: 8.4rem;
  }

  #sub_title {
    font-size: 4.8rem;
  }
}

@media screen and (min-width: 1050px) and (max-width: 1300px) {
  #main_title {
    font-size: 64px;
  }

  #sub_title {
    font-size: 40px;
  }

  .diamond_svg {
    right: 30px;
    top: 50px;
    width: 370px;
    height: 600px;
  }

  .hexagon_diamond_svg {
    position: absolute;
    right: 0;
  }

  #jewelry_title {
    font-size: 5rem;
  }

  .footer_title {
    font-size: 3.8rem !important;
  }
  .footer_description {
    font-size: 3.2rem !important;
    line-height: 38px;
    margin-bottom: 25px;
  }
}

@media screen and (min-width: 750px) and (max-width: 1050px) {
  #text-over-container {
    left: 40px;
  }
  #main_title {
    font-size: 46px;
  }

  #sub_title {
    font-size: 26px;
  }

  .diamond_svg {
    right: 0;
    top: 36px;
    width: 300px;
    height: 425px;
  }

  #jewelry_title {
    font-size: 3.6rem;
    font-weight: 700;
  }

  .hexagon_diamond_svg {
    position: absolute;
    right: 0px;
    top: -30px;
    height: 320px;
  }

  .main_header_button {
    margin-top: 32px;
    font-size: 1.6rem;
  }
  .feature-card {
    margin: auto 75px;
  }
}

@media screen and (min-width: 600px) and (max-width: 750px) {
  #text-over-container {
    left: 20px;
  }
  #main_title {
    font-size: 36px;
  }

  #sub_title {
    font-size: 24px;
  }
  .diamond_svg {
    right: 0;
    top: 30px;
    width: 240px;
    height: 320px;
  }

  #jewelry_title {
    font-size: 3.2rem;
    font-weight: 700;
  }

  .hexagon_diamond_svg {
    position: absolute;
    right: 0;
    top: -30px;
    width: 280px;
    height: 260px;
  }

  .main_header_button {
    margin-top: 22px;
    width: 220px;
    height: 44px;
    padding: 15px 20px;
    font-size: 1.5rem;
  }
  .feature-card {
    margin: auto 40px;
  }
  .footer_title {
    font-size: 3.8rem !important;
  }
  .footer_description {
    font-size: 3.5rem !important;
    line-height: 28px;
    margin-bottom: 45px;
  }
}

@media screen and (min-width: 450px) and (max-width: 600px) {
  .main-page-container {
    margin-top: 100px;
  }

  #text-over-container {
    left: 20px;
  }
  #main_title {
    font-size: 26px;
  }

  #sub_title {
    font-size: 18px;
  }
  .diamond_svg {
    right: 0;
    top: 10px;
    width: 220px;
    height: 240px;
  }

  #jewelry_title {
    font-size: 3rem;
    font-weight: 700;
  }

  .hexagon_diamond_svg {
    position: absolute;
    right: -10px;
    top: -30px;
    width: 240px;
    height: 220px;
  }

  .main_header_button {
    margin-top: 10px;
    padding: 10px;
    width: 120px;
    height: 22px;
    font-size: 1.3rem;
  }
  .feature-card {
    margin: auto 15px;
  }

  .mainpage_footer {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 0 50px;
  }

  .footer_title {
    font-size: 3.2rem !important;
  }
  .footer_description {
    font-size: 3rem !important;
    line-height: 28px;
    margin-bottom: 20px;
  }
}

@media screen and (max-width: 450px) {
  .main-page-container {
    margin-top: 100px;
  }

  #text-over-container {
    left: 20px;
  }

  #main_title {
    font-size: 20px;
  }

  #sub_title {
    font-size: 13px;
  }
  .diamond_svg {
    right: 20px;
    top: 40px;
    width: 120px;
    height: 150px;
  }

  #jewelry_title {
    font-size: 2.4rem;
    font-weight: 700;
  }

  .hexagon_diamond_svg {
    position: absolute;
    top: -30px;
    left: 60%;
    width: fit-content;
    height: 140px;
  }

  .main_header_button {
    margin-top: 10px;
    width: 150px;
    height: 15px;
    padding: 10px 12px;
    font-size: 1.4rem;
  }
  .feature-card {
    margin: auto 5px;
  }

  .mainpage_footer {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 0 50px;
  }
}

@media screen and (max-width: 380px) {
  #main_title {
    font-size: 16px;
  }

  #text-over-container {
    top: 32px;
  }

  #sub_title {
    font-size: 10px;
  }

  #jewelry_title {
    font-size: 2rem;
  }

  .diamond_svg {
    right: 10px;
    top: 28px;
    width: 120px;
    height: 130px;
  }
}

@media screen and (min-width: 425px) and (max-width: 500px) {
  .hexagon_diamond_svg {
    position: absolute;
    top: -20px;
    left: 55%;
    width: 200px;
    height: 140px;
  }
  #jewelry_title {
    font-size: 2.4rem;
  }
}

@media screen and (min-width: 385px) and (max-width: 425px) {
  .hexagon_diamond_svg {
    position: absolute;
    top: -20px;
    left: 45%;
    width: 200px;
    height: 140px;
  }
  #jewelry_title {
    font-size: 2.4rem;
  }
}

@media screen and (max-width: 385px) {
  .hexagon_diamond_svg {
    position: absolute;
    top: -20px;
    left: 60%;
    height: 110px;
    overflow: hidden;
  }
}

@media screen and (max-width: 768px) {
  #card_description {
    padding: 10px 20px;
    line-height: 25px;
    text-align: center;
  }

  .result_card {
    margin-bottom: 20px;
  }

  .results_description {
    line-height: 22px;
  }
}
