.igl-about-us-container {
  margin-top: 150px;
  overflow: clip;
  position: relative;
}

.about_img {
  position: absolute;
  top: -90px;
  height: 400px;
  z-index: -1;
  right: 0;
}

.team-container {
  margin-bottom: 90px;
}

.about_service_container {
  margin-bottom: 100px;
}

.igl-about-info {
  padding: 40px 20px;
  align-items: center;
  margin-bottom: 68px;
  border-radius: 68px;
  box-shadow: -13px 18px 29px 0px rgba(0, 0, 0, 0.03);
}

.about-title {
  color: #000;
  font-family: Merriweather;
  font-size: 9.6rem;
  font-weight: 700;
}

.about-sub-title {
  color: #4d4d4d;
  font-family: Merriweather;
  font-size: 3.2rem;
  font-weight: 400;
  margin-bottom: 30px;
}

.about-description {
  color: #575757;
  font-family: Merriweather;
  font-size: 2.4rem;
  font-weight: 400;
}

.team-title {
  color: #000;
  font-family: Montserrat;
  font-size: 6.4rem;
  font-style: normal;
  font-weight: 700;
  line-height: 112.3%;
  margin-bottom: 43px;
}

.team-description {
  color: #000;
  font-family: Montserrat;
  font-size: 3.6rem;
  font-weight: 400;
}

.about_service_title {
  color: #000;
  font-family: Jaldi;
  font-size: 3.6rem;
  font-weight: 700;
  text-align: center;
}

.about_service_description {
  color: #000;
  text-align: center;
  font-family: Jaldi;
  font-size: 2.4rem;
  font-weight: 400;
  line-height: 34px; /* 141.667% */
}

.service_card_stroke {
  border: 3px solid #d0d7f2;
  border-radius: 20px;
  padding: 15px;
}

.show_count_container {
  padding: 65px 105px;
  justify-content: center;
  align-items: center;
  background: #e4e9fd url(../../assets/rectangle359_gradient.svg) no-repeat left bottom;
}

.count_number {
  color: #000;
  font-family: Montserrat;
  font-size: 6rem;
  font-weight: 700;
  text-align: center;
}

.count_title {
  color: #000;
  font-family: Montserrat;
  font-size: 2.7rem;
  font-weight: 400;
  text-align: center;
}

.brief-service-container {
  margin-top: 100px;
}

.brief-detail-card-container {
  margin-bottom: 175px;
}

.brief-card-border-styling {
  border-right: 1px solid #E0E0E0;
  padding: 5px;
}

.brief-title {
  color: #000;
  font-family: Montserrat;
  font-size: 6.4rem;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  margin-bottom: 40px;
}

.brief-description {
  color: #000;
  font-family: Montserrat;
  font-size: 3.6rem;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  margin-bottom: 100px;
}

.image-circle {
  display: flex;
  width: 88px;
  height: 88px;
  padding: 5px 25px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 10px;
  border-radius: 60px;
  border: 1px solid #d4d4d4;
  background-color: #e4e9fd;
  filter: drop-shadow(-11px 14px 12px rgba(0, 0, 0, 0.25));
}

.brief-detail-card {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.brief-detail-card-title {
  margin: 30px 0px;
  color: #000;
  text-align: center;
  font-family: Montserrat;
  font-size: 36px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
}

.brief-detail-card_description {
  color: #575757;
  text-align: center;
  font-family: Merriweather;
  font-size: 24px;
  font-style: normal;
  font-weight: 400;
  line-height: 36px; /* 150% */
  padding: 5px;
}

@media screen and (max-width: 1024px) {
  .igl-about-us-container {
    margin: 150px 15px 0px 15px;
  }
  .show_count_container {
    padding: 65px 30px;
  }
}

@media screen and (max-width: 769px) {
  .about_service_description,
  .brief-detail-card_description {
    line-height: normal;
  }

  .brief-detail-card-title {
    margin: 15px 0px;
  }

  .brief-detail-card_description {
    margin-bottom: 40px;
  }

  .brief-detail-card-container {
    margin-bottom: 50px;
  }
}

@media screen and (max-width: 768px) {
  .about_img {
    height: 300px;
    top: -75px;
    right: -25px;
  }

  .brief-card-border-styling {
    border-style: none;
    padding: 0px !important;
  }
}

@media screen and (max-width: 992px) {
  .service_card_stroke {
   margin-bottom: 20px;
  }
}
