.check-report-container {
  margin: 200px auto 200px auto;
}

.check-report-title-container {
  margin-bottom: 70px;
}

.report-check-title {
  color: #333;
  font-family: Montserrat;
  font-size: 6.4rem;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  padding-bottom: 35px;
  border-bottom: 3px dashed #bdbdbd;
}

.check-report-desc-container {
  margin-bottom: 90px;
}

.report-check-desc {
  color: #333;
  font-family: Montserrat;
  font-size: 3.6rem;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

.report-number-input-container {
  width: 100%;
  height: 165px;
  display: flex;
  align-items: center;
  border-radius: 20px;
  border: 1px solid #8e96b5;
  background: #e4e9fd;
  margin: 0 !important;
}

#report_number_input_field {
  background-color: #fff !important;
  width: 100%;
  height: 70px;
  padding: 39px;
  margin: 0 !important;
  border-radius: 16px;
  font-size: 2.4rem;
}

.report-number-input-field {
  padding: 0 30px;
  z-index: 2 !important;
}

.check_report_button_container {
  display: flex;
  justify-content: center;
}

.check_report_button {
  display: flex;
  width: 200px;
  height: 65px;
  padding: 18px 24px;
  justify-content: center;
  align-items: center;
  gap: 10px;
  border-radius: 36px;
  background: #242224;
  color: #fff;
  font-size: 2.4rem;
  border-color: transparent;
  font-family: Montserrat;
}

@media screen and (min-width: 576px) and (max-width: 768px) {
  .check_report_button {
    width: 150px;
    height: 55px;
    border-radius: 20px;
  }
}

@media screen and (max-width: 576px) {
  .check-report-container {
    margin: 200px auto 100px auto;
  }
  .check-report-title-container,
  .check-report-desc-container {
    padding-left: 25px;
    padding-right: 25px;
  }

  .report-number-input-container {
    width: 92%;
    margin: 0px auto !important;
  }

  #report_number_input_field {
    background-color: #fff !important;
    width: 100%;
    height: 60px;
    padding: 15px 30px;
  }

  .check_report_button {
    width: 90%;
    height: 55px;
    border-radius: 16px;
    padding: 0 20px;
    cursor: pointer;
  }
  .report-number-input-field {
    padding: 0 20px;
  }
}
