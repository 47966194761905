.contact-us-container {
  margin-top: 100px;
}

.contact-info {
  background-color: #efefef;
  padding: 125px 114px 124px 55px;
  align-items: center;
  margin-bottom: 150px;
}

.contact-title {
  color: #000;
  font-family: Jaldi;
  font-size: 6.4rem;
  font-weight: 600;
}

.contact-description {
  color: #000;
  font-family: Jaldi;
  font-size: 3.2rem;
  font-weight: 400; 
}

.appointment-form {
  background-color: #efefef;
  padding: 125px 114px 124px 55px;
}

.appointment-title {
  color: #000;
  font-family: Jaldi;
  font-size: 6.4rem;
  font-weight: 600;
}

.appointment-description {
  color: #000;
  font-family: Jaldi;
  font-size: 3.6rem;
  font-weight: 400; 
  margin-bottom: 30px;
}

.form-control {
  background-color: transparent !important;
  margin-bottom: 30px;
  padding: 5px;
  font-size: 20px;
}

.css-13cymwt-control {
  background-color: transparent !important;
  padding-bottom: 2px;
}

.css-1fdsijx-ValueContainer {
  background-color: transparent !important;
  font-size: 20px;
  text-indent: 25px;
}

/* #react-select-3-listbox {
  font-size: 1.6rem !important;
} */

@media screen and (max-width: 768px) {
  .contact-info {
    background-color: #efefef;
    padding: 125px 55px 124px 55px;
    align-items: center;
  margin-bottom: 50px;
  }

  .appointment-form {
    background-color: #efefef;
    padding: 125px 55px 124px 55px;
  }  
}