.pdf-main-container {
position: relative;
width: 100%;
min-height: 2140px !important;
}

.pdf-header {
    position: relative;
    top: 0;
    height: 150px;
    width: 100%;
    background-color: black;
}

.pdf-pattern {
    position: relative;
    top: 6px;
    background: url(../../assets/pattern.png) no-repeat left top; 
}

.pdf-title-container {
    display: flex;
    justify-content: start;
    align-items: center;
}

.pdf-logo {
    height: 160px;
    width: 200px;
    margin: auto 30px;
    background: url(../../assets/igl_logo.svg) no-repeat; 
    background-size: contain;
}

.pdf-title {
    position: relative;
    left: -75px;
    top: -5px;
    font-size: 85px;
    font-weight: 700;
    color: white;
}

.pdf-footer {
    position: absolute;
    bottom: 0;
    width: 100%;
    height: 100px;
    background-color: #f3f3f3;
    border-top: 0.5px solid rgba(84, 81, 81,0.5);
    display: flex;
    justify-content: end;
}

.pdf-footer-img {
    width: 100px;
    height: 90px;
    position: relative;
    top: -50px;
    margin-right: 30px;
    mix-blend-mode: darken;
}