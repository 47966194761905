.nav-main-container{
  position: fixed;
  top: 0;
  z-index: 10;
  background-color: #fff;
}

.nav-container {
  height: 110px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-left: 0 !important;
  margin-right: 0 !important;
}

.hamburger-menu {
  display: none;
}

/* .igl_logo {
  padding-left: 50px;
} */

.navbar_list {
  display: flex;
  justify-content: flex-end;
}

.menubar-items {
  margin-right: 40px;
  font-size: 1.8rem; 
  font-weight: 500;
  cursor: pointer;
  color: #000;
  text-decoration: none;
  text-align: center;
}

.active .menubar-items {
  font-weight: bold;
  scale: 1.1;
  
}

.hamburger-menubar-items {
  margin-right: 40px;
  font-size: 18px; 
  font-weight: 500;
  cursor: pointer;
  color: #000;
  text-decoration: none;
}

.hamburger-menu-container {
  position: relative;
}

.close-hamburger-icon {
  position: absolute;
  right: 0;
  margin-right: 30px;
  top: -20px;
  border: none;
}

.igl-logo-fullform {
  display: flex;
  align-items: center;
}

.igl-fullForm-card-container {
  display: flex;
  flex-direction: column;
  align-items: left;
  font-size: 24px;
  font-weight: bold;
  color: #000;
}

@media screen and (max-width: 1000px) {
  .igl_logo {
    padding-left: 10px;
  }
  .menubar-items {
    margin-right: 25px;
  }
}

@media screen and (max-width: 768px) {
  .igl_logo {
    padding-left: 10px;
  }
  .navbar_list {
    padding-right: 20px;
  }
}

@media screen and (max-width: 600px) {
  .nav-container {
    display: flex;
    justify-content: start;
    align-items: center;
    margin-left: 0 !important;
    margin-right: 0 !important;
  }
  
  .navbar_list,.menubar-items {
  display: none;
  }
  .hamburger-menu {
    display: flex;
    justify-content: end;
    align-items: center;
    padding-right: 30px;
  }
}

/* @media screen and (max-width: 600px) {
  .igl_logo {
    padding: 0;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
  }
  .navbar_list {
    padding: 0;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
  }
  .menubar-items {
    margin-bottom: 10px;
  }
} */
