.diamond-education-container {
  padding: 95px 266px 95px 50px;
  justify-content: center;
  align-items: center;
  background: #e4e9fd url(../../assets/diam_edu_bg.png) no-repeat right bottom;
  margin-top: 100px;
  margin-bottom: 140px;
  overflow: clip;
}

.diam-edu-title {
  color: #000;
  font-family: Montserrat;
  font-size: 6.4rem;
  font-weight: 700;
  text-align: left;
}

.diam-edu-description {
  color: #000;
  font-family: Merriweather;
  font-size: 3.2rem;
  font-weight: 400;
  text-align: left;
  margin-bottom: 33px;
}

.diam_edu_button {
  display: flex;
  width: 200px;
  height: 65px;
  padding: 18px 24px;
  justify-content: center;
  align-items: center;
  gap: 10px;
  border-radius: 36px;
  background: #242224;
  color: #fff;
  font-size: 2.4rem;
  border-color: transparent;
  font-family: Montserrat;
}

.get-started {
  margin-bottom: 58px;
}

.edu-get-started-title {
  color: #000;
  font-family: Montserrat;
  font-size: 4.8rem;
  font-weight: 700;
  margin-bottom: 52px;
}

.edu-get-started-description {
  color: #000;
  font-family: Merriweather;
  font-size: 2.4rem;
  font-weight: 400;
}

.edu-card-outline {
  border-radius: 18px;
  background: linear-gradient(white, white, white) padding-box,
    linear-gradient(#4C48FF, #DD65FB) border-box;
  border: 2px solid transparent;
  padding: 49px 35px;
  position: relative;
  margin-bottom: 50px;
  margin-right: 54px;
  transition: 0.3s ease;
}

/* .edu-card-outline:hover {
  border: 2px solid black;
  background: none;
} */

/* .edu-card-outline:before {
  content: "";
  position: absolute;
  bottom: 0;
  left: 0;
  width: 0%;
  height: 0%;
  clip-path: circle(50% at 0 100%);
  background: rgb(227, 232, 252,0.7); 
  transition: 0.8s ease;
  z-index: -0.5;
} */

/* .edu-card-outline:hover:before {
  width: 110%;
  height: 100%;
  clip-path: polygon(0 0, 100% 0, 100% 100%, 0% 100%);
}  */

.edu-information_card_title {
  color: #000;
  font-family: Montserrat;
  font-size: 24px;
  font-weight: 700;
  position: absolute;
  top: -18px;
  background-color: white;
  padding-left: 13px;
  padding-right: 13px;
  z-index: 9;
}

.edu-information_card_description {
  color: #000;
  text-align: center;
  font-family: Montserrat;
  font-size: 2.4rem;
  font-weight: 400;
}

.diam-education-information {
  margin-bottom: 100px;
}

@media screen and (max-width: 1024px) {
  .diamond-education-container {
    padding: 95px 50px;
  }
}

@media screen and (max-width: 768px) {
  .diamond-education-container {
    margin-bottom: 50px;
  }
  .edu-get-started-title,
  .edu-get-started-description,
  .information_card {
    padding-left: 25px;
    padding-right: 25px;
  }
}
