.mainpage_footer {
  height: 612px;
  width: 100%;
  background-color: #111;
  position: relative;
  padding: 70px 248px 196px 97px;
  z-index: 1;
  overflow-x: clip;
  /* align-items: center; */
}

.moon_svg {
  position: absolute;
  top: -300px;
  right: 0;
  z-index: -1;
}

.footer_title {
  color: #fff;
  font-family: Montserrat;
  font-size: 4.8rem;
  font-weight: 700;
  margin-bottom: 5px;
  text-shadow: 2px 7px 5px rgba(0, 0, 0, 0.3),
    0px -4px 10px rgba(255, 255, 255, 0.3);
}

.footer_description {
  color: #fff;
  font-family: Montserrat;
  font-size: 4.2rem;
  font-style: normal;
  font-weight: 300;
  line-height: 54px; /* 128.571% */
  margin-bottom: 58px;
  text-shadow: 2px 7px 5px rgba(0, 0, 0, 1), 0px -4px 10px rgba(0, 0, 0, 1);
}

.footer_button {
  display: flex;
  width: 279px;
  height: 52px;
  padding: 18px 24px;
  align-items: flex-start;
  justify-content: center;
  align-items: center;
  gap: 10px;
  border-radius: 18px 4px;
  background: #ffffff;
  color: #000000;
  font-size: 2rem;
  color: #000;
  font-family: Montserrat;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  margin-bottom: 50px;
}

.company-info-container {
  margin-bottom: 30px;
}

.footer-company-info-title {
  color: #fff;
  font-family: Montserrat;
  font-size: 24px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  margin-bottom: 12px;
}

.footer-company-info-description {
  color: #fff;
  font-family: Montserrat;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  margin-bottom: 12px;
}

.copyright-footer {
  position: absolute;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  color: #fff;
  font-family: Montserrat;
  font-size: 2.4rem;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  margin-top: 15px;
  border-top: 1px solid #fff;
  bottom: 8px;
  left: 5px;
  padding-left: 50px;
  padding-right: 50px;
  padding-top: 15px;
}

@media screen and (min-width: 750px) and (max-width: 1600px) {
  .footer_title {
    font-size: 3.4rem !important;
  }
  .footer_description {
    font-size: 3rem !important;
    line-height: 38px;
    margin-bottom: 22px;
  }
}

@media screen and (min-width: 600px) and (max-width: 1086px) {
  .mainpage_footer {
    padding: 60px 75px 196px 97px;
  }
}

@media screen and (min-width: 600px) and (max-width: 750px) {
  .footer_title {
    font-size: 3rem !important;
  }
  .footer_description {
    font-size: 2.6rem !important;
    line-height: 28px;
    margin-bottom: 35px;
  }
}

@media screen and (max-width: 600px) {
  .mainpage_footer {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: start;
    padding: 0 50px;
  }

  .company-info-container {
    margin-bottom: 20px;
  }

  .footer-company-info-title {
    font-size: 16px;
  }

  .copyright-footer {
    font-size: 12px;
  }

  .footer-company-info-description {
    font-size: 12px;
  }

  .footer_title {
    font-size: 2.6rem !important;
  }
  .footer_description {
    font-size: 2.4rem !important;
    line-height: 28px;
    margin-bottom: 20px;
  }

  .footer_button {
    width: 200px;
    font-size: 14px;
  }

  .mainpage_footer {
    padding: 10px 20px 40px 20px;
  }
}

@media screen and (max-width: 600px) {
  .moon_svg {
    left: -360px;
  }
}
