.report-form-container {
  margin: 5px 25px 20px 25px;
  padding: 80px 55px;
  border: 1px solid #666;
  border-radius: 38px;
}

.export-certificate-container {
  margin: 50px 25px 50px 25px;
  padding: 25px;
  border-radius: 16px;
  background-color: #e4e9fd;
}

.form-row {
  margin-bottom: 45px;
}

.title-field-container {
  display: flex;
  align-items: center;
  margin-bottom: 10px;
}

.report-summary-image {
  /* background: url(../../assets/report_summary_img.png) no-repeat; */
  width: 250px;
  height: 250px;
  border-radius: 28px;
  margin-bottom: 28px;
}

.align-value-field {
  margin-left: 45px;
}

.form-title {
  color: #171717;
  font-family: Merriweather;
  font-size: 2.8rem;
  font-weight: 400;
}

.form-value-background {
  border: 1px solid transparent;
  border-radius: 12px;
  background: #f3f3f3;
  padding: 10px 20px;
}

.form-value {
  color: #171717;
  font-family: Merriweather;
  font-size: 2.8rem;
  font-weight: 400;
  text-align: left;
}

.export-image-container {
  width: 222px;
  height: 112px;
  border-radius: 16px;
  background: #fff;
}

.export-certi-text {
  color: #333;
  font-family: Merriweather;
  font-size: 3.6rem;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  text-decoration-line: underline;
}

.export-certi-center {
  display: flex;
  justify-content: center;
  align-items: center;
}

@media screen and (max-width: 769px) {
  .export-image-container {
    margin: 0 auto;
  }
  .export-certi-text-container {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .export-certi-text {
    padding-left: 0;
  }
}

@media screen and (max-width: 650px) {
  .report-form-container {
    margin: 0px 25px 20px 25px;
    padding: 20px 20px;
    border: 1px solid #666;
    border-radius: 20px;
  }

  .form-value-background {
    border: 1px solid transparent;
    border-radius: 12px;
    background: #f3f3f3;
    padding: 10px 10px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 10px;
  }
}

@media screen and (min-width: 1180px) and (max-width: 1300px) {
  .align-value-field {
    margin-left: 40px;
  }
}

@media screen and (min-width: 992px) and (max-width: 1180px) {
  .align-value-field {
    margin-left: 32px;
  }
}

@media screen and (max-width: 992px) {
  .align-value-field {
    margin-left: 0px;
  }
}

@media screen and (min-width: 769px) and (max-width: 1090px) {
.export-image-container {
  width: 150px;
  height: 95px;
  border-radius: 13px;
  background: #fff;
}
}

@media screen and (max-width: 400px) {
  .export-image-container {
    width: 160px;
    height: 100px;
    border-radius: 13px;
    background: #fff;
  }
  }